<div class="quick-filter">
  <dynamic-type-input *ngIf="this.currentResourceTypeMetadata && this.currentInputData"
                      [metadataProviderType]="'VIEW'"
                      [metadata]="this.currentResourceTypeMetadata"
                      [value]="this.currentValue"
                      [inputData]="this.currentInputData"
                      [inputType]="this.currentInputType"
                      [floatingLabel]="false"
                      [allowClear]="false"
                      [openInDropdown]="true"
                      [resourceEntityProvider]="this.resourceEntityProvider.bind(this)"
                      (valueChange)="this.onValueChange($event)">

    <ng-template robawsNgTemplate="input" let-onClick>
      <div class="input-container"
           tooltipPosition="top"
           [pTooltip]="this.currentPath?.displayName ?? ''"
           [attr.data-has-value]="this.currentValue && this.currentValue.length > 0"
           (click)="onClick($event)">
        <span class="input-label">
          {{ (this.shouldShowCount() ? '(' + this.getCurrentValueCount() + ') ' : '') + (this.currentPath?.displayName ?? '') }}
        </span>

        @if (this.currentValue && this.currentValue.length > 0) {
          <mat-icon fontIcon="close_small" class="quick-filter-clear-icon" (click)="this.clear()" />
        }
        <mat-icon fontIcon="arrow_drop_down" class="quick-filter-dropdown-icon" />
      </div>
    </ng-template>

  </dynamic-type-input>
</div>
