export class RobawsConstants {
  public static readonly STANDALONE_FILTER_OVERLAY_UPDATE = 'StandaloneFilterOverlayUpdate';
  public static readonly TOAST_DURATION_SHORT = 3_000;
  public static readonly TOAST_DURATION_MEDIUM = 5_000;
  public static readonly TOAST_DURATION_LONG = 10_000;
  public static readonly IGNORE_DELETED_AT = 'IGNORE_DELETED_AT';
  public static readonly IGNORE_ARCHIVED_AT = 'IGNORE_ARCHIVED_AT';
  public static readonly FORCE_DYNAMIC_OVERVIEW_REFRESH = 'forceDynamicOverviewRefresh';
  public static readonly ADDITIONAL_VIEW_DATA_PREFIX = 'additionalViewData:';
}
