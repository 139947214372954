import { AfterViewChecked, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { InternalServiceMessageService, Path, PathService, UrlService } from '@shared/services';
import { AbstractWebComponent } from '@shared/components/abstract-web.component';
import { CommonModule } from '@angular/common';
import { DataType, ResourceTypeMetadata } from '@shared/domain';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { View, ViewContentType } from '@app/robaws/domain';
import { Tab, ViewTabsComponent } from '@app/robaws/components/dynamic-overview/view-tabs/view-tabs.component';
import { SkeletonModule } from 'primeng/skeleton';
import { ViewService } from '@app/robaws/services/view.service';
import { ViewDataRow } from '@app/robaws/domain/ViewDataRow';
import { MenuItem, SortMeta } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { MatIcon } from '@angular/material/icon';
import { RippleModule } from 'primeng/ripple';
import { DynamicOverviewPaginatorComponent } from '@app/robaws/components/dynamic-overview/dynamic-overview-paginator/dynamic-overview-paginator.component';
import { ViewSortCreateDTO } from '@app/robaws/domain/ViewSortCreateDTO';
import { RobawsNgDialogComponent } from '@ui/robaws-ng-dialog/robaws-ng-dialog.component';
import { ViewSettingsDialogComponent } from '@app/robaws/components/dynamic-overview/view-settings-dialog/view-settings-dialog.component';
import { ViewFilters, ViewFiltersComponent } from '@app/robaws/components/dynamic-overview/view-filters/view-quick-filters/view-filters.component';
import { bindNativeMethod } from '@app/shared/helpers/injection.helper';
import { RobawsResourceTypeEntityService } from '@app/robaws/services';
import { AlertHelper } from '@shared/helpers';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';
import { RobawsBulkAction } from '@app/robaws/domain/RobawsBulkAction';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContextMenuModule } from 'primeng/contextmenu';
import { AutoFocus } from 'primeng/autofocus';
import { switchMap, tap } from 'rxjs/operators';
import { isTouchDevice } from '@app/shared/helpers/device.helper';
import { ViewSettingsDTO } from '@app/robaws/components/dynamic-overview/view-settings/view-settings.component';
import { ViewDataRowColumn } from '@app/robaws/domain/ViewDataRowColumn';

type ViewDataRowWithTextColor = ViewDataRow & { textColor: 'black' | 'white' };

type ViewColumnVO = {
  path: string;
  sortable: boolean;
  primary: boolean;
  valueProvider: (data: ViewDataRowWithTextColor) => any;
  navigateTo: (data: ViewDataRowWithTextColor) => void;
  canNavigate: (data: ViewDataRowWithTextColor) => boolean;
};

type ContextMenuAction = {
  id: string;
  icon: string;
  iconColor: string;
  text: string;
};

type SearchOptions = {
  searchText: string;
  viewId: string | null;
  currentPage: number;
  pageLimit: number;
};

@Component({
  selector: 'dynamic-overview',
  templateUrl: 'dynamic-overview.component.html',
  styleUrls: ['dynamic-overview.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ViewTabsComponent,
    SkeletonModule,
    InputTextModule,
    FormsModule,
    NgModelChangeDebouncedDirective,
    MatIcon,
    RippleModule,
    DynamicOverviewPaginatorComponent,
    RobawsNgDialogComponent,
    ViewSettingsDialogComponent,
    ViewFiltersComponent,
    ContextMenuModule,
    AutoFocus,
    TranslateModule,
  ],
})
export class DynamicOverviewComponent extends AbstractWebComponent implements OnInit, AfterViewChecked {
  @Input({ required: true })
  public viewContentType: ViewContentType;

  @Input({ required: true })
  public resourceType: string;

  @Input({ required: true })
  public analytics: string;

  @Input({ required: true })
  public calendar: string;

  @Input({ required: true })
  public financials: string;

  protected metadata: ResourceTypeMetadata;
  protected placeholderRows: number[];
  protected viewLoading = true;
  protected dataLoading = false;
  protected currentView: View;
  protected viewColumns: ViewColumnVO[] = [];
  protected currentRows: ViewDataRowWithTextColor[] = [];
  protected selectedItems: ViewDataRowWithTextColor[] = [];
  protected totalRows: number = 0;
  protected sorts: SortMeta[] = [];
  protected previousSorts: SortMeta[] = [];
  protected searchText: string = '';
  protected currentPage: number = 0;
  protected metadataPaths: Path[];
  protected contextMenuItems: MenuItem[] = [];
  protected readonly isTouchDevice = isTouchDevice;
  @ViewChild(Table)
  private table?: Table;
  @ViewChild(ViewSettingsDialogComponent)
  private viewSettingsDialog: ViewSettingsDialogComponent;
  @ViewChild(ViewTabsComponent)
  private viewTabsComponent: ViewTabsComponent;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('VIEW');
  private currentScrollTop: number = 0;

  constructor(
    protected override viewContainerRef: ViewContainerRef,
    private pathService: PathService,
    private viewService: ViewService,
    private urlService: UrlService,
    private robawsResourceTypeEntityService: RobawsResourceTypeEntityService,
    private alertHelper: AlertHelper,
    private translateService: TranslateService,
    private internalServiceMessageService: InternalServiceMessageService,
  ) {
    super(viewContainerRef);

    bindNativeMethod('getSelectedRowIds', this.getSelectedRowIds.bind(this));
    bindNativeMethod('onAttach', this.onAttach.bind(this));
    bindNativeMethod('deleteSelectedItems', this.deleteSelectedItems.bind(this));
    bindNativeMethod('archiveSelectedItems', this.archiveSelectedItems.bind(this));
    bindNativeMethod('lockSelectedItems', this.lockSelectedItems.bind(this));
    bindNativeMethod('getCurrentSearchOptions', this.getCurrentSearchOptions.bind(this));
    bindNativeMethod('reloadView', this.reloadView.bind(this));
    bindNativeMethod('getTotalResults', this.getTotalResults.bind(this));
  }

  @Input()
  public set contextMenuActions(contextMenuActionsJson: string) {
    const contextMenuActions: ContextMenuAction[] = JSON.parse(contextMenuActionsJson);

    this.contextMenuItems = contextMenuActions.map((action) => {
      return {
        icon: action.icon,
        label: action.text,
        iconStyle: { color: action.iconColor },
        command: () => {
          this.internalServiceMessageService.dispatch('context-menu-action-clicked', {
            viewContentType: this.viewContentType,
            actionId: action.id,
          });
        },
      };
    });
  }

  public ngOnInit(): void {
    this.placeholderRows = Array(30)
      .fill(0)
      .map((_, i) => i);

    this.pathService
      .getPaths(this.dynamicResourceTypeProvider, this.resourceType, false, true, true, true, true, true)
      .subscribe((paths) => (this.metadataPaths = paths));

    this.dynamicResourceTypeProvider.getMetadata(this.resourceType).subscribe((data) => (this.metadata = data));
  }

  public ngAfterViewChecked(): void {
    if (!this.isTableSynced()) {
      this.restoreColumnWidthsFromCurrentView();
    }
  }

  public getSelectedRowIds(): string[] {
    return this.selectedItems.map((it) => it.id);
  }

  public onAttach(): void {
    if (localStorage.getItem(RobawsConstants.FORCE_DYNAMIC_OVERVIEW_REFRESH) === 'true') {
      localStorage.removeItem(RobawsConstants.FORCE_DYNAMIC_OVERVIEW_REFRESH);

      // if the overview hasn't been loaded before, the viewTabsComponent won't be available yet and therefore there is no need to update the tabs
      if (this.viewTabsComponent) {
        this.viewLoading = true;
        this.viewTabsComponent.updateTabs();
      }
    } else {
      if (this.viewTabsComponent && !this.viewTabsComponent.isAnyTabsLoaded()) {
        // if for whatever reason (f.e. backend outage) there aren't any tabs loaded, we want to re-fetch the tabs.
        this.viewLoading = true;
        this.viewTabsComponent.updateTabs();
      } else {
        setTimeout(() => this.refresh(false, true), 1000);
      }
    }
  }

  public deleteSelectedItems(): void {
    this.executeEntityBulkAction('DELETE', 'UNDELETE');
  }

  public archiveSelectedItems(): void {
    this.executeEntityBulkAction('ARCHIVE', 'UNARCHIVE');
  }

  public lockSelectedItems(): void {
    this.executeEntityBulkAction('LOCK', 'UNLOCK');
  }

  public getCurrentSearchOptions(): SearchOptions {
    return {
      searchText: this.searchText,
      viewId: this.currentView?.id ?? null,
      currentPage: this.currentPage,
      pageLimit: this.currentView?.pageSize ?? 20,
    };
  }

  public reloadView(): void {
    this.loadViewPage(0);
  }

  public getTotalResults(): number {
    return this.totalRows;
  }

  protected isLoaded() {
    return this.metadata && this.metadataPaths;
  }

  protected onTabChange(tab: Tab): void {
    this.loadView(tab.view);

    if (tab.isNew) {
      this.viewSettingsDialog.openDialog();
    }
  }

  protected onRowDoubleClick(event: MouseEvent, rowData: ViewDataRowWithTextColor): void {
    event.preventDefault();
    this.urlService.navigateToResourceType(this.resourceType, rowData.id);
  }

  protected loadViewData(event: TableLazyLoadEvent): void {
    if (this.dataLoading) {
      return;
    }
    this.dataLoading = true;

    const pageSize = event.rows || 20;
    const first = event.first || 0;
    const pageIndex = first / pageSize;

    if (this.checkIfSortsChanged(event.multiSortMeta)) {
      const sorts = (event.multiSortMeta ?? [])
        .map((sort) => {
          return {
            path: sort.field,
            sortDirection: sort.order === 1 ? 'ASC' : 'DESC',
          } as ViewSortCreateDTO;
        })
        .filter((it) => it.path && it.path.length > 0);

      if (sorts.length === 0) {
        return;
      }

      this.viewService.updateSorts(this.currentView.id, sorts).subscribe((sorts) => {
        this.currentView.sorts = sorts;
        this.updateSorts();

        this.loadViewPage(pageIndex, pageSize);
      });
    } else {
      this.loadViewPage(pageIndex, pageSize);
    }
  }

  protected onSearchTextUpdate(text: string): void {
    if (this.searchText === text) {
      return;
    }
    this.searchText = text;
    this.loadViewPage(0);
  }

  protected openSettingsDialog(): void {
    this.viewSettingsDialog.openDialog();
  }

  protected loadViewPage(page: number, pageSize?: number, showDataLoader: boolean = true, afterLoad?: () => void): void {
    if (this.currentView.type === 'ANALYTICS') {
      this.toggleAnalytics(true);
      this.updateAnalytics();
    } else if (this.currentView.type === 'CALENDAR') {
      this.toggleCalendar(true);
      this.updateCalendar();
    } else if (this.currentView.type === 'FINANCIALS') {
      this.toggleFinancials(true);
      this.updateFinancials();
    } else {
      if (this.currentView.columns.length === 0) {
        return;
      }
      if (showDataLoader) {
        this.dataLoading = true;
      }
      this.toggleAnalytics(false);
      this.toggleCalendar(false);

      this.viewService.getViewData(this.currentView.id, this.searchText, page, pageSize ?? this.currentView.pageSize).subscribe((data) => {
        if (data.lastForceRefresh && data.lastForceRefresh !== this.currentView.lastForceRefresh) {
          // a system view got updated and columns may be mismatched, so we need to reload the view
          this.viewTabsComponent.updateTabs();
          return;
        }

        this.currentRows = data.rows.map((it) => {
          const textColor = this.calculateTextColorBasedOnBackground(it.color);

          return { ...it, textColor };
        });
        this.currentPage = data.currentPage;
        this.totalRows = data.totalRows;
        this.viewLoading = false;

        if (showDataLoader) {
          this.dataLoading = false;
        }

        if (!this.isTableSynced()) {
          this.restoreColumnWidthsFromCurrentView();
        }

        if (afterLoad) {
          afterLoad();
        }
      });
    }
  }

  protected onPageSizeChange(pageSize: number): void {
    this.dataLoading = true;

    this.viewService.updatePageSize(this.currentView.id, pageSize).subscribe(() => {
      this.currentView.pageSize = pageSize;
      this.loadViewPage(0, pageSize);
    });
  }

  protected onViewSettingsChanged(viewSettings: ViewSettingsDTO): void {
    this.viewLoading = true;

    this.viewService
      .updateViewNameAndVisibility(this.currentView.id, viewSettings.name, viewSettings.visibility)
      .pipe(
        tap(() => {
          this.currentView.name = viewSettings.name;
          this.currentView.visibility = viewSettings.visibility;
        }),
        switchMap(() =>
          this.viewService.updateColumns(
            this.currentView.id,
            viewSettings.columns.map((it) => ({ dataPath: it })),
          ),
        ),
      )
      .subscribe((columns) => {
        this.currentView.columns = columns;

        // resetting the table and column widths to let the table recalculate the column widths
        this.viewService.updateTableAndColumnWidths(this.currentView.id, null, null).subscribe(() => {
          this.currentView.tableWidth = undefined;
          this.currentView.columnWidths = undefined;

          this.loadView(this.currentView);
        });

        this.viewTabsComponent.updateTabs();
      });
  }

  protected onColumnResize(): void {
    if (!this.currentView || !this.table) {
      return;
    }

    const state: any = {};
    this.table.saveColumnWidths(state);

    this.viewService.updateTableAndColumnWidths(this.currentView.id, state.tableWidth, state.columnWidths).subscribe(() => {
      this.currentView.tableWidth = state.tableWidth;
      this.currentView.columnWidths = state.columnWidths;
    });
  }

  protected restoreColumnWidthsFromCurrentView(): void {
    if (!this.currentView || !this.table) {
      return;
    }

    if (this.currentView.columnWidths) {
      this.table.columnWidthsState = this.currentView.columnWidths;
    }
    if (this.currentView.tableWidth) {
      this.table.tableWidthState = String(this.currentView.tableWidth);
    }

    this.table.restoreColumnWidths();
  }

  protected onFiltersSaved(viewFilters: ViewFilters): void {
    this.currentView.additionalFilters = viewFilters.additionalFilters;
    this.loadViewPage(0);
  }

  protected onScroll(): void {
    if (this.table) {
      this.currentScrollTop = this.table.el.nativeElement.querySelector('.p-datatable-wrapper').scrollTop;
    }
  }

  protected onViewDeleted(): void {
    this.viewTabsComponent.updateTabs();
  }

  // fix for shift selecting rows while clicking on the checkbox (see: https://github.com/primefaces/primeng/issues/5496)
  protected checkRangeSelect(event: MouseEvent, index: number) {
    if (this.table) {
      if (event.button === 0 && event.shiftKey) {
        this.table.selectRange(event, index);
        this.table.anchorRowIndex = null;
      } else {
        this.table.anchorRowIndex = index;
      }
    }
  }

  protected switchToTable(): void {
    if (this.currentView.type === 'TABLE') {
      return;
    }
    this.viewService.updateViewType(this.currentView.id, 'TABLE').subscribe(() => {
      this.currentView.type = 'TABLE';
      this.toggleAnalytics(false);
      this.loadViewPage(0);
    });
  }

  protected switchToAnalytics(): void {
    if (this.currentView.type === 'ANALYTICS') {
      return;
    }
    this.viewService.updateViewType(this.currentView.id, 'ANALYTICS').subscribe(() => {
      this.currentView.type = 'ANALYTICS';
      this.toggleAnalytics(true);
      this.updateAnalytics();
    });
  }

  protected switchToCalendar(): void {
    if (this.currentView.type === 'CALENDAR') {
      return;
    }
    this.viewService.updateViewType(this.currentView.id, 'CALENDAR').subscribe(() => {
      this.currentView.type = 'CALENDAR';
      this.toggleCalendar(true);
      this.updateCalendar();
    });
  }

  protected switchToFinancials(): void {
    if (this.currentView.type === 'FINANCIALS') {
      return;
    }
    this.viewService.updateViewType(this.currentView.id, 'FINANCIALS').subscribe(() => {
      this.currentView.type = 'FINANCIALS';
      this.toggleFinancials(true);
      this.updateFinancials();
    });
  }

  protected updateAnalytics(): void {
    if (!this.isAnalyticsEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch('update-analytics', {
      viewContentType: this.viewContentType,
    });
  }

  protected updateCalendar(): void {
    if (!this.isCalendarEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch('update-calendar', {
      viewContentType: this.viewContentType,
    });
  }

  protected updateFinancials(): void {
    if (!this.isFinancialsEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch('update-financials', {
      viewContentType: this.viewContentType,
    });
  }

  protected isAnalyticsEnabled(): boolean {
    return this.analytics === 'true';
  }

  protected isCalendarEnabled(): boolean {
    return this.calendar === 'true';
  }

  protected isFinancialsEnabled(): boolean {
    return this.financials === 'true';
  }

  private toggleAnalytics(visible: boolean): void {
    if (!this.isAnalyticsEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch(visible ? 'show-analytics' : 'hide-analytics', {
      viewContentType: this.viewContentType,
    });
  }

  private toggleCalendar(visible: boolean): void {
    if (!this.isCalendarEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch(visible ? 'show-calendar' : 'hide-calendar', {
      viewContentType: this.viewContentType,
    });
  }

  private toggleFinancials(visible: boolean): void {
    if (!this.isFinancialsEnabled()) {
      return;
    }
    this.internalServiceMessageService.dispatch(visible ? 'show-financials' : 'hide-financials', {
      viewContentType: this.viewContentType,
    });
  }

  private loadView(view: View): void {
    this.currentView = view;
    this.viewLoading = view.columns.length > 0; // don't trigger loading animation if there's no columns anyway
    this.searchText = '';
    this.updateSearchTextFromLocalStorageIfNecessary();

    this.viewColumns = view.columns.map((column) => {
      const path = this.metadataPaths.find((p) => p.path === column.dataPath);
      const dataType = path?.dataType ?? DataType.TEXT;
      const sortable = path?.sortable ?? false;

      return {
        path: column.dataPath,
        name: path?.displayNameDeep ?? column.dataPath,
        sortable: dataType !== DataType.COMPLEX && sortable,
        primary: path?.primary ?? false,
        canNavigate: (rowData: ViewDataRowWithTextColor): boolean => {
          return (path?.primary ?? false) && (path?.depth === 0 || !!this.findColumnData(column.id, column.dataPath, rowData)?.parentId);
        },
        valueProvider: (rowData: ViewDataRowWithTextColor) => {
          const value = this.findColumnData(column.id, column.dataPath, rowData)?.value ?? '';

          if (value === '') {
            return '';
          }

          if (dataType === DataType.DATE || dataType === DataType.DATE_TIME) {
            const date = new Date(value);

            if (dataType === DataType.DATE) {
              return date.toLocaleDateString();
            } else {
              return date
                .toLocaleString(undefined, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: undefined,
                })
                .replace(/,/g, '');
            }
          } else if (dataType === DataType.BOOLEAN && typeof value === 'boolean') {
            return value ? this.translateService.instant('yes') : this.translateService.instant('no');
          } else {
            return value;
          }
        },
        navigateTo: (rowData: ViewDataRowWithTextColor) => {
          const idToNavigateTo = this.findColumnData(column.id, column.dataPath, rowData)?.parentId ?? rowData.id;

          this.urlService.navigateToResourceType(path?.parentMetadata.name ?? this.resourceType, idToNavigateTo);
        },
      };
    });
    this.updateSorts();
    this.loadViewPage(0);
  }

  private findColumnData(columnId: string, columnPath: string, rowData: ViewDataRowWithTextColor): ViewDataRowColumn | undefined {
    return rowData.columns.find((it) => it.columnId === columnId || it.columnPath === columnPath);
  }

  private executeEntityBulkAction(action: RobawsBulkAction, undoAction: RobawsBulkAction): void {
    const selectedIds = this.getSelectedRowIds();

    if (selectedIds.length === 0) {
      this.alertHelper.fireToast('error', this.translateService.instant('overviews.no-items-selected'), RobawsConstants.TOAST_DURATION_SHORT);
      return;
    }

    this.dataLoading = true;
    this.robawsResourceTypeEntityService
      .executeBulkAction(
        this.resourceType,
        selectedIds.map((id) => ({
          action: action,
          resourceId: id,
        })),
      )
      .subscribe(() => {
        this.refresh(true);

        this.alertHelper
          .fireToast(
            'success',
            this.translateService.instant(`overviews.bulk-actions.${action}.success`),
            RobawsConstants.TOAST_DURATION_MEDIUM,
            true,
            this.translateService.instant('undo'),
          )
          .then((result) => {
            if (result.isDenied) {
              this.robawsResourceTypeEntityService
                .executeBulkAction(
                  this.resourceType,
                  selectedIds.map((id) => ({
                    action: undoAction,
                    resourceId: id,
                  })),
                )
                .subscribe(() => {
                  this.refresh(true);

                  this.alertHelper.fireToast(
                    'success',
                    this.translateService.instant(`overviews.bulk-actions.${action}.undone`),
                    RobawsConstants.TOAST_DURATION_SHORT,
                  );
                });
            }
          });
      });
  }

  private updateSorts(): void {
    this.sorts = this.currentView.sorts.map((sort) => {
      return {
        field: sort.path,
        order: sort.sortDirection === 'ASC' ? 1 : -1,
      };
    });
    this.previousSorts = this.currentView.sorts.map((sort) => {
      return {
        field: sort.path,
        order: sort.sortDirection === 'ASC' ? 1 : -1,
      };
    });
  }

  private isTableSynced(): boolean {
    if (!this.currentView || !this.table) {
      return true;
    }
    return String(this.currentView.tableWidth) === this.table.tableWidthState && this.currentView.columnWidths === this.table.columnWidthsState;
  }

  private checkIfSortsChanged(newSorts: SortMeta[] | null | undefined): boolean {
    if (!newSorts) {
      return this.previousSorts && this.previousSorts.length > 0;
    }

    if (newSorts.length !== this.previousSorts.length) {
      return true;
    }

    for (let i = 0; i < newSorts.length; i++) {
      if (newSorts[i].field !== this.previousSorts[i].field || newSorts[i].order !== this.previousSorts[i].order) {
        return true;
      }
    }

    return false;
  }

  private calculateTextColorBasedOnBackground(hex: string | undefined): 'black' | 'white' {
    if (!hex) {
      return 'black';
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (result) {
      const [red, green, blue] = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];

      return red * 0.299 + green * 0.587 + blue * 0.114 > 150 ? 'black' : 'white';
    }

    return 'black';
  }

  private refresh(withLoader: boolean, restoreScrollState = false): void {
    if (this.currentView) {
      if (restoreScrollState && this.table) {
        // restoring scroll position
        this.table.el.nativeElement.querySelector('.p-datatable-wrapper').scrollTop = this.currentScrollTop;
      }

      const selectedIds = this.getSelectedRowIds();

      this.loadViewPage(this.currentPage, this.currentView.pageSize, withLoader, () => {
        this.selectedItems = this.currentRows.filter((it) => selectedIds.includes(it.id));
      });
    }
  }

  private updateSearchTextFromLocalStorageIfNecessary(): void {
    const searchKey = `VIEW_${this.viewContentType}_SEARCH_TEXT`;
    const searchValue = localStorage.getItem(searchKey);

    if (searchValue) {
      this.searchText = searchValue;
      localStorage.removeItem(searchKey);
    }
  }
}
