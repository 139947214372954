<mat-accordion>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'overviews.settings.quick-filters' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>

    @if (this.metadata) {
      @for (path of quickFilterPaths; track path) {
        <div class="quick-filter-path">
          <property-path-selector [metadataProviderType]="'ROBAWS'"
                                  [data]="{ metadata: this.metadata, path: path }"
                                  [showLabel]="false"
                                  [filter]="this.filterAdditionalViewDataPaths"
                                  (propertySelected)="this.handleQuickFilterPathSelected($index, $event)"/>

          <delete-icon (deleteAccepted)="this.deleteQuickFilterPath($index)"/>
        </div>
      }

      <button class="add-quick-filter robaws-ng-muted-button"
              (click)="this.addQuickFilterPath()"
              [attr.disabled]="this.quickFilterPaths.length >= 10 ? 'disabled' : null"
              pAutoFocus>
        <mat-icon fontIcon="add"/>
        {{ 'overviews.quickfilters.add' | translate }}
      </button>
    }
  </mat-expansion-panel>

  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'overviews.settings.system-view' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>

    @if (this.systemView) {
      <view-settings [view]="this.systemView"
                     [paths]="this.metadataPaths"
                     [metadata]="this.metadata"/>
    }
  </mat-expansion-panel>

</mat-accordion>
