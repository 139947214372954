import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Path, PathService, TreePath } from '@shared/services';
import { MetadataProviderType, MetadataWithPath } from '@shared/domain';
import { map } from 'rxjs/operators';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';
import { TreeSelectComponent } from '@ui/tree-select/tree-select.component';
import { TreeNode } from '@ui/tree-select/tree-node.type';

export type PathResultFilter = (path: Path) => boolean;

type TreePathWithFilterResult = TreePath & {
  isSelectable: boolean;
  children: TreePathWithFilterResult[];
};

@Component({
  selector: 'property-path-selector',
  templateUrl: 'property-path-selector.component.html',
  styleUrls: ['property-path-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [TreeSelectComponent, NgIf, TranslateModule, MatButton, MaterialLoaderDirective, PrimaryColorDirective],
  standalone: true,
})
export class PropertyPathSelectorComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public metadataProviderType: MetadataProviderType;

  @Input({ required: true })
  public data: MetadataWithPath;

  @Input()
  public includeReadOnly = true;

  @Input()
  public includeLinks = true;

  @Input()
  public fetchDeep = true;

  @Input()
  public appendToBody = false;

  @Input()
  public spreadComplexTypes = true;

  @Input()
  public includeComplexTypes = false;

  @Input()
  public filter: PathResultFilter | undefined;

  @Input()
  public showLabel = true;

  @Output()
  public propertySelected: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public cancelled: EventEmitter<void> = new EventEmitter<void>();

  protected isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected paths: TreePathWithFilterResult[] = [];
  protected nodes: TreeNode[] = [];
  protected currentPropertyPath?: string;
  private dynamicResourceTypeProvider: DynamicResourceTypeProvider;

  constructor(
    private pathService: PathService,
    protected translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.dynamicResourceTypeProvider = new DynamicResourceTypeProvider(this.metadataProviderType);
    this.currentPropertyPath = this.data.path;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.data.metadata) {
      return;
    }
    if (changes['metadataProviderType']) {
      this.dynamicResourceTypeProvider = new DynamicResourceTypeProvider(this.metadataProviderType);
    }
    if (changes['data']) {
      this.currentPropertyPath = this.data.path;
    }
    this.reload();
  }

  protected handleSelect(propertyPath: string): void {
    this.currentPropertyPath = propertyPath;
    this.propertySelected.emit(this.currentPropertyPath);
  }

  private reload(): void {
    this.isLoading$.next(true);
    this.pathService
      .getTreePaths(
        this.dynamicResourceTypeProvider,
        this.data.metadata.name,
        false,
        this.includeLinks,
        this.includeReadOnly,
        this.fetchDeep,
        this.spreadComplexTypes,
        this.includeComplexTypes,
      )
      .pipe(map((paths) => this.filterAndSortPaths(paths)))
      .subscribe((paths: TreePathWithFilterResult[]) => {
        this.paths = paths;
        this.nodes = this.mapPathsToNodes(paths);

        this.isLoading$.next(false);

        if (this.currentPropertyPath && !this.isPathFound(this.currentPropertyPath, this.paths)) {
          this.currentPropertyPath = '';
          this.propertySelected.emit('');
        }
      });
  }

  private filterAndSortPaths(paths: TreePath[]): TreePathWithFilterResult[] {
    return paths
      .map((path: TreePath) => {
        const pathCopy = { ...path, isSelectable: this.filter ? this.filter(path) : true } as TreePathWithFilterResult;
        if (pathCopy.children) {
          pathCopy.children = this.filterAndSortPaths(path.children);
        }
        return pathCopy;
      })
      .filter((path: TreePathWithFilterResult): boolean => {
        if (this.filter) {
          return path.isSelectable || path.children.length > 0; // check if path passes filter OR if any of its children passed the filter
        }
        return true;
      })
      .sort(this.sortPaths);
  }

  private sortPaths(a: TreePath, b: TreePath): number {
    if (a.pathType == null || b.pathType == null) {
      return 0;
    }
    return b.pathType.localeCompare(a.pathType);
  }

  private mapPathsToNodes(paths: TreePathWithFilterResult[]): TreeNode[] {
    return paths.map((path) => ({
      value: path.path,
      label: path.displayName,
      selectedLabel: path.displayNameDeep,
      children: this.mapPathsToNodes(path.children),
      selectable: path.isSelectable,
    }));
  }

  private isPathFound(path: string, paths: TreePath[]): boolean {
    for (const treePath of paths) {
      if (treePath.path === path) {
        return true;
      } else {
        if (treePath.children && this.isPathFound(path, treePath.children)) {
          return true;
        }
      }
    }
    return false;
  }
}
