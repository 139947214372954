import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { View } from '@app/robaws/domain';
import { Path } from '@shared/services';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatIcon } from '@angular/material/icon';
import { ViewVisibilityType } from '@app/robaws/domain/ViewVisibilityType';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';
import { AlertHelper } from '@shared/helpers';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';
import { ViewService } from '@app/robaws/services/view.service';
import { robawsWindow } from '@app/shared/helpers/window.helper';
import { NgIf } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { ListboxModule } from 'primeng/listbox';
import { TreeSelectComponent } from '@ui/tree-select/tree-select.component';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { PropertyPathSelectorComponent } from '@shared/components/property-path-selector';
import { ResourceTypeMetadata } from '@shared/domain';

export type ViewSettingsDTO = {
  columns: string[];
  name: string;
  visibility: ViewVisibilityType;
};

type ViewColumnVO = {
  path: string;
  displayName: string;
};

@Component({
  selector: 'view-settings',
  templateUrl: 'view-settings.component.html',
  styleUrls: ['view-settings.component.scss'],
  standalone: true,
  imports: [
    CheckboxModule,
    FormsModule,
    InputTextModule,
    CdkDrag,
    CdkDropList,
    MatIcon,
    TranslateModule,
    DeleteIconComponent,
    NgIf,
    TooltipModule,
    ListboxModule,
    TreeSelectComponent,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    PropertyPathSelectorComponent,
  ],
})
export class ViewSettingsComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public view: View;
  @Input({ required: true })
  public paths: Path[];
  @Input({ required: true })
  public metadata: ResourceTypeMetadata;
  @Output()
  public viewDeleted = new EventEmitter<View>();
  protected name: string;
  protected visibility: ViewVisibilityType;
  protected selectedColumns: ViewColumnVO[] = [];
  protected searchText = '';
  protected expandedIndex: number;

  constructor(
    private alertHelper: AlertHelper,
    private translateService: TranslateService,
    private viewService: ViewService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.updateProperties();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['view'] || changes['paths']) {
      this.updateProperties();
    }
  }

  public getCurrentSettings(): ViewSettingsDTO {
    return {
      columns: this.selectedColumns.map((it) => it.path),
      name: this.name,
      visibility: this.visibility,
    };
  }

  protected canDeleteView(): boolean {
    return this.view && !this.view.systemView && !this.view.parentView?.systemView;
  }

  protected onDrop(event: CdkDragDrop<any, any>): void {
    moveItemInArray(this.selectedColumns, event.previousIndex, event.currentIndex);
  }

  protected toggleVisibility(): void {
    this.visibility = this.visibility === 'PRIVATE' ? 'EVERYONE' : 'PRIVATE';

    this.alertHelper.fireToast(
      'success',
      this.translateService.instant('overviews.settings.visibility-changed-to-' + this.visibility.toLowerCase()),
      RobawsConstants.TOAST_DURATION_SHORT,
    );
  }

  protected deleteView(): void {
    if (!this.canDeleteView()) {
      return;
    }
    robawsWindow.startLoader();

    this.viewService.deleteView(this.view.id).subscribe(() => {
      this.viewDeleted.emit(this.view);
      robawsWindow.stopLoader();
    });
  }

  protected removeSelectedColumn(index: number): void {
    this.selectedColumns.splice(index, 1);
  }

  protected handlePropertySelect(column: ViewColumnVO, path: string): void {
    column.path = path;
    column.displayName = this.getDisplayName(path);
  }

  protected addColumn(): void {
    this.selectedColumns.push({
      path: '',
      displayName: '',
    });
    this.expandedIndex = this.selectedColumns.length - 1;
    this.changeDetectorRef.detectChanges();
  }

  private updateProperties() {
    if (this.view) {
      this.name = this.view.name;
      this.visibility = this.view.visibility;
      this.selectedColumns = this.view.columns.map((it) => ({
        path: it.dataPath,
        displayName: this.getDisplayName(it.dataPath),
      }));
    }
  }

  protected getDisplayName(path: string): string {
    return this.paths.find((it) => it.path === path)?.displayNameDeep ?? '';
  }
}
